<script>
import { mapActions } from "vuex";
import IconBase from "../components/icons/_icon-base.vue";
import iconTim from "../components/icons/_icon-tim.vue";
import createGtmScript from "../utils/create-gtm-script";

export default {
  name: "Checkout",
  components: {
    IconBase,
    iconTim,
  },

  mounted() {
    this.resetModal();
    this.clearOrder("");
    this.clearCatalog();
    const utm_referrer =
      window.document.referrer !== "" ? window.document.referrer : "direto";
    const company = this.$route.query.company || "app";
    const vitrine = this.$route.query.vitrine || "pap";
    const queries = { ...this.$route.query, utm_referrer, company, vitrine };
    const gtm = queries.gtm ?? process.env.VUE_APP_GTM;
    this.setGtm(gtm);
    createGtmScript(gtm, "gtm-config");
    this.setQueriesParams(queries);
    this.routerOrchestror(queries);
    this.setAddress({ cep: queries.cep, numero: queries.numero });
    this.setPersonalData({
      telefone: queries.cel || queries.telefone,
      nomeCompleto: queries.nome,
    });
    // this.$tracking.send().finally(() => {
    // this.handleAutoFetch(queries);
    // });
  },
  methods: {
    ...mapActions({
      resetModal: "modal/resetModal",
      clearOrder: "order/clearData",
      clearCatalog: "catalog/clearData",
      setQueriesParams: "setQueriesParams",
      setAddress: "order/setAddress",
      setPersonalData: "order/setPersonalData",
      setGtm: "ui/setGtm",
    }),

    async routerOrchestror(queries) {
      if (queries.redirect) return await this.$router.push(queries.redirect);
      return this.$router.push("/consulta-cobertura");
    },
    handleAutoFetch({ cel, telefone, numero, cep }) {
      if ((cel || telefone) && numero && cep)
        this.$store.dispatch("ui/setAutoFetchStatus", true);
    },
  },
};
</script>

<template>
  <div
    class="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center text-primary"
  >
    <icon-base :size="16"><icon-tim /></icon-base>
  </div>
</template>
